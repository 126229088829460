import './vimeo.scss'

import React, { Component } from 'react'

import Axios from 'axios'

class Vimeo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vimeoError: false,
      showPlayer: false,
      title: '',
      thumbUrl: '',
    }
  }

  async componentDidMount() {
    await Axios.get(`https://vimeo.com/api/v2/video/${this.props.id}.json`)
      .then(res => {
        this.setState({
          thumbUrl: res.data[0].thumbnail_large.replace('_640.jpg', '_900.jpg'),
          title: res.data[0].title,
        })
      })
      .catch(() => {
        this.setState({
          vimeoError: true,
        })
      })
  }

  handleClick = () => {
    this.setState({
      showPlayer: true,
    })
  }

  render() {
    const vimeoStyle = {
      backgroundImage: `url("${this.state.thumbUrl}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }

    const vimeoUrl = `https://player.vimeo.com/video/${this.props.id}?autoplay=1&color=cd9164&byline=0&portrait=0`

    if (this.state.vimeoError) {
      return null
    }

    if (this.state.showPlayer) {
      return (
        <div className="vimeo">
          <iframe
            src={vimeoUrl}
            title={this.state.title}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )
    } else {
      return (
        <div className="vimeo" style={vimeoStyle}>
          <button className="vimeo-overlay" onClick={this.handleClick}>
            <h2>{this.state.title}</h2>
          </button>
        </div>
      )
    }
  }
}

export default Vimeo
