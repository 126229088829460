import './index.scss'

import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from '../components/seo'
import SimpleBar from 'simplebar-react'
import Vimeo from '../components/vimeo'
import { graphql } from "gatsby"

class Index extends Component {

  constructor(props) {
    super(props)

    this.state = {
      videoCount: this.props.data.wordpressPage.acf.video_list.length,
      videoOffset: 11
    }
  }

  handleLoadMore = () => {
    this.setState({
      videoOffset: this.state.videoOffset + 10 > this.state.videoCount? this.state.videoCount : this.state.videoOffset + 10
    })
  }

  render() {
    const page = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title={page.yoast_meta.title} description={page.yoast_meta.desc} />
        <section className="page-left page-about--left">
          <div className="page-left--wrapper">
            <SimpleBar style={{ maxHeight: '100%' }}>
              <div className="page-left--content page-about--content">
                <p className="p-strong">
                  <strong>We make films</strong><br />that people remember
                </p>
                <p className="p-normal">Our award-winning, collaborative, and hands-on approach guarantees engaging and memorable projects that resonate emotionally on any media.</p>
                <p className="p-strong">
                  <strong>WE PRODUCE:</strong>
                </p>
                <p className="p-normal">
                  TV &amp; online commercials, social media content, branded content, corporate videos, documentaries, music videos, interactive live video, streaming production and photo sessions. 
                </p>
                <p className="p-strong">
                  <strong>WE DO IT ALL</strong>, AND WE<br />
                  DO IT EXCEPTIONALLY.
                </p>
                <p className="p-normal">No matter how "big" or "small" the project, our Clients receive the highest quality work executed in the most efficient manner.</p>
              </div>
            </SimpleBar>
          </div>
        </section>
        <section className="page-right">
          <div className="page-about--video-list">
            {page.acf.video_list && page.acf.video_list.filter((el, i) => i < this.state.videoOffset).map(video => {
              return (
                <Vimeo key={video.vimeo_id} id={video.vimeo_id} />
              )
            })
            }
            {this.state.videoCount !== this.state.videoOffset &&
              <button className="load-more-btn" onClick={this.handleLoadMore}>Load more</button>
            }
          </div>
        </section>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "about" }) {
      title
      slug
      content
      yoast_meta {
        title
        desc
      }
      acf {
        video_list {
          vimeo_id
        }
      }
    }
  }
`